import React from 'react'
import Link from 'gatsby-link'
import Layout from '../components/Layout'

import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const halfTd = { width: '50%' }

export default class DonorsPage extends React.Component {
  render() {
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content">
              <h1>Donor Recognition</h1>
              <p>
                The Phi Sigma Rho Foundation is pleased to recognize Annual
                &amp; Lifetime Giving Clubs.
              </p>
              <p>
                Annual gifts to Phi Sigma Rho Foundation are a tax-deductible
                way to demonstrate your belief in the promise and potential of
                the women of Phi Sigma Rho. Unrestricted annual donations are
                used to fund Fraternity grants, operating costs and the vital
                programs that make our mission possible. The Phi Sigma Rho
                Foundation would like to recognize our generous donors who help
                make the Foundation programs possible. Annual and Lifetime Donor
                Recognition is updated annually below and are also recognized
                through The Key Magazine and at conferences and conventions.
                Because of the generous support of donors who give annually, we
                are able to make an immediate impact on the Sorority, her
                members and the larger community.
              </p>
              <br />
              <p>
                Donors can view their giving history by signing in to the Phi
                Rho Portal and clicking on My Giving History.
              </p>
              <br />
              <a
                href="https://portal.phisigmarho.org"
                className="button is-large is-rounded"
                style={{
                  fontFamily: 'Quicksand',
                  fontWeight: 'bold',
                  display: 'block',
                  color: '#8e2344',
                  backgroundColor: 'white',
                  border: '3px #8e2344 solid',
                  borderRadius: 0,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  width: '40%',
                  minWidth: '200px',
                }}
              >
                Phi Rho Portal
              </a>
              <br />
              <br />
              <h1>Lifetime Donors</h1>
              <div>
                <div className="container">
                  <p>
                  Donors' cumulative
                  lifetime giving to any fund within the Foundation are recognized
                  in our Lifetime Giving Societies.
                  </p>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <h3 style={{ margin: 0 }}>Platinum Society ($500,000)</h3>
                    </AccordionSummary>
                    <AccordionDetails></AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <h3 style={{ margin: 0 }}>Silver Society ($250,000)</h3>
                    </AccordionSummary>
                    <AccordionDetails></AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <h3 style={{ margin: 0 }}>Founders Society ($100,000)</h3>
                    </AccordionSummary>
                    <AccordionDetails></AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <h3 style={{ margin: 0 }}>
                        Changemaker Society ($50,000)
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails></AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <h3 style={{ margin: 0 }}>
                        Influencer Society ($25,000)
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails>
                      <table>
                        <tr style={{borderWidth: 0}}>
                          <td style={halfTd}>Elana Chapman</td>
                          <td style={halfTd}>Charlene Yauch</td>
                        </tr>
                        <tr style={{borderWidth: 0}}>
                          <td style={halfTd}>Jennifer &amp; Bryan Roach</td>
                          <td style={halfTd}>Lewis A. and Carol W. McDonald Charitable Trust</td>
                        </tr>
                      </table>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <h3 style={{ margin: 0 }}>Visionary Society ($10,000)</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                      <table>
                        <tr>
                          <td style={halfTd}>Christine Jackson</td>
                          <td style={halfTd}>Rosalie Krob</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Heather Monigan (Berger)</td>
                          <td style={halfTd}>Robert &amp; Carol Rogers</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Rashmi Drummond</td>
                          <td style={halfTd}>Abby McDonald Schwartz</td>
                        </tr>
                      </table>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <h3 style={{ margin: 0 }}>Scholarship Society ($5,000)</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                      <table>
                        <tr>
                          <td style={halfTd}>Janelle Becker</td>
                          <td style={halfTd}>Lara Belcher Day</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Hillary Emer</td>
                          <td style={halfTd}>Harold &amp; Joyce Godeke</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Heather Lazar</td>
                          <td style={halfTd}>Abby McDonald Schwartz</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Belle Riehmann</td>
                          <td style={halfTd}>Kellina Anderson</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Anonymous (1)</td>
                          <td style={halfTd}></td>
                        </tr>
                      </table>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <h3 style={{ margin: 0 }}>Friendship Society ($2,500)</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                      <table>
                        <tr>
                          <td style={halfTd}>Belle Riehemann</td>
                          <td style={halfTd}>Jacqueline Crowley</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Kellina Anderson</td>
                          <td style={halfTd}>Teresa Fourqurean</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Katherine Hoppert</td>
                          <td style={halfTd}>Elishka Jepson</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Caitlin LaFontaine</td>
                          <td style={halfTd}>Allison Lenhoff</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Amy Mazzanti</td>
                          <td style={halfTd}>Melissa Moran</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Leanne Price</td>
                          <td style={halfTd}>Janet Reyna</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Nicolette Yovanof</td>
                          <td style={halfTd}></td>
                        </tr>
                      </table>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <h3 style={{ margin: 0 }}>Encouragement Society ($1,000)
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails>
                      <table>
                        <tr>
                          <td style={halfTd}>Margaret Allen</td>
                          <td style={halfTd}>Christine Barman</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Amanda Brown</td>
                          <td style={halfTd}>Erika Bustos</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Alexandria Caliguire</td>
                          <td style={halfTd}>Rachel Chicchi</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Dominique Fantasia-Amin</td>
                          <td style={halfTd}>Sara Fisk</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Carin Gleason</td>
                          <td style={halfTd}>Harrison Hayworth</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Lexi Heironimus</td>
                          <td style={halfTd}>Mona Kim</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Maryanne Ludden</td>
                          <td style={halfTd}>Ashley Mohler</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Kendall Murphy</td>
                          <td style={halfTd}>Renee Pelan</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Kellan Ponikiewicz</td>
                          <td style={halfTd}>Brittany Potter</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Rebecca Riley</td>
                          <td style={halfTd}>Kristina Schnell</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Katherine Shesko</td>
                          <td style={halfTd}>Uma Staehler</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Rebecca Trudeau</td>
                          <td style={halfTd}>Kat Wenger</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Lacey Westcarr</td>
                          <td style={halfTd}>RebeccaSchmidt</td>
                        </tr>
                      </table>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
              <br />
              <br />
              <div>
                <h1>2024 Annual Donors</h1>
                <div className="container">
                  <p>
                  Cumulative annual gifts to Foundation between a calendar year
                  are joined in our Annual Giving Clubs.
                  </p>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <h3 style={{ margin: 0 }}>Star Club ($10,000)</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                      <table>
                        <tr>
                          <td style={halfTd}>Jennifer Roach</td>
                          <td style={halfTd}>Abby McDonald Schwartz</td>
                        </tr>
                        <tr>
                          <td colspan="2">Updated as of 2025-03-02</td>
                        </tr>
                      </table>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <h3 style={{ margin: 0 }}>Prosperity Club ($5,000)</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                      <table>
                        <tr>
                          <td style={halfTd}>Belle Riehemann</td>
                          <td style={halfTd}></td>
                        </tr>
                        <tr>
                          <td colspan="2">Updated as of 2025-03-02</td>
                        </tr>
                      </table>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <h3 style={{ margin: 0 }}>Loyalty Club ($2,500)</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                      <table>
                        <tr>
                          <td style={halfTd}>Rosalie Krob</td>
                          <td style={halfTd}></td>
                        </tr>
                        <tr>
                          <td colspan="2">Updated as of 2025-03-02</td>
                        </tr>
                      </table>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <h3 style={{ margin: 0 }}>1984 Club ($1,984)</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                      <table>
                        <tr>
                          <td style={halfTd}>Heather Monigan (Berger)</td>
                          <td style={halfTd}>Melissa Moran</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Carol Rogers</td>
                          <td style={halfTd}></td>
                        </tr>
                        <tr>
                          <td colspan="2">Updated as of 2025-03-02</td>
                        </tr>
                      </table>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <h3 style={{ margin: 0 }}>Rising Sun Club ($1,000)</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                      <table>
                        <tr>
                          <td style={halfTd}>Kellina Anderson</td>
                          <td style={halfTd}>Janelle Becker</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Jacquelyn Crowley</td>
                          <td style={halfTd}>Caitlin LaFontaine</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Amy Mazzanti</td>
                          <td style={halfTd}>Charlene Yauch</td>
                        </tr>
                        <tr>
                          <td colspan="2">Updated as of 2025-03-02</td>
                        </tr>
                      </table>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <h3 style={{ margin: 0 }}>Pearl Club ($500)</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                      <table>
                        <tr>
                          <td style={halfTd}>Amanda Brown</td>
                          <td style={halfTd}>Hillary Emer</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Dana Fowlkes</td>
                          <td style={halfTd}>Lexi Heironimus</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Christine Jackson</td>
                          <td style={halfTd}>Elishka Jepson</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Rebecca Schmidt</td>
                          <td style={halfTd}></td>
                        </tr>
                        <tr>
                          <td colspan="2">Updated as of 2025-03-02</td>
                        </tr>
                      </table>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <h3 style={{ margin: 0 }}>Pyramid Club ($250)</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                      <table>
                        <tr>
                          <td style={halfTd}>Michelle Eyink</td>
                          <td style={halfTd}>Caroline King</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Dan & Laura Leaverton</td>
                          <td style={halfTd}>Kendall Murphy</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Regina Schiavoni</td>
                          <td style={halfTd}>Tina Schnell</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Madison Thomas</td>
                          <td style={halfTd}>Shannon Vondrak</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Lacey Westcarr</td>
                          <td style={halfTd}>Mary Beth White</td>
                        </tr>
                        <tr>
                          <td colspan="2">Updated as of 2025-03-02</td>
                        </tr>
                      </table>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <h3 style={{ margin: 0 }}>Key Club ($100)</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                      <table>
                        <tr>
                          <td style={halfTd}>Alaina Schwall</td>
                          <td style={halfTd}>Alison Lunny</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Andrea Knodel</td>
                          <td style={halfTd}>Anna Mathena</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Anna Discenzo</td>
                          <td style={halfTd}>April Romer</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Ashley Durcholz</td>
                          <td style={halfTd}>Cindy Stotler</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Darlene Nelson</td>
                          <td style={halfTd}>Elizabeth Holloway</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Emily Dalton</td>
                          <td style={halfTd}>Gabrielle Salazar</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Jennifer Duncan</td>
                          <td style={halfTd}>Jessi Kalinowski</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Karryn Loveday</td>
                          <td style={halfTd}>Kathleene Lammert</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Katie Gurgal</td>
                          <td style={halfTd}>Kenneth Musson</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Lauren Garland</td>
                          <td style={halfTd}>Laurel Peterson</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Lauren Wheeler</td>
                          <td style={halfTd}>Melissa Fontanese</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Michelle Stoutenborough</td>
                          <td style={halfTd}>Morganne Neuman</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Pam Hintz</td>
                          <td style={halfTd}>Pamela Osby</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Rebecca Riley</td>
                          <td style={halfTd}>Renee Pelan</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Rinda Vas</td>
                          <td style={halfTd}>Robin Amo</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Sandy Junge</td>
                          <td style={halfTd}>Sarah Miller</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Susan Maciag</td>
                          <td style={halfTd}>Tabitha Mlakar</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Tara Zanhke</td>
                          <td style={halfTd}>Victoria Marandina</td>
                        </tr>
                        <tr>
                          <td colspan="2">Updated as of 2025-03-02</td>
                        </tr>
                      </table>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <h3 style={{ margin: 0 }}>Sigmand Club ($50)</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                      <table>
                        <tr>
                          <td style={halfTd}>Adwoa Adarkwa-Darko</td>
                          <td style={halfTd}>Allison Ward</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Ashely Mohler</td>
                          <td style={halfTd}>Cheryl Chapman</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Emily Jensen</td>
                          <td style={halfTd}>Harrison Hayworth</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Heather Trombley</td>
                          <td style={halfTd}>Jessica Sofka</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Kerry Gregory</td>
                          <td style={halfTd}>Laura Belcher Day</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Laurel Rawley</td>
                          <td style={halfTd}>Lauren Meert</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Lee Thomas</td>
                          <td style={halfTd}>Lisa Nguyen</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Patia Fann</td>
                          <td style={halfTd}>Starli Bartlett</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Taylor Gilger</td>
                          <td style={halfTd}>Vicky McNicholl</td>
                        </tr>
                        <tr>
                          <td colspan="2">Updated as of 2025-03-02</td>
                        </tr>
                      </table>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
              <br />
              <div>
                <div className="container">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <h3 style={{ margin: 0 }}>Circle of Contributions</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p>
                        Donors become a member of the Circle of Contributions
                        after giving an annual gift of any size for five
                        consecutive years. The Foundation is thankful for the
                        continued support of our donors over the years.
                      </p>
                      <table>
                        <tr>
                          <td style={halfTd}>Marcie Aydelotte</td>
                          <td style={halfTd}>Janelle Becker</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Erika Bustos</td>
                          <td style={halfTd}>Lista Canterbury</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Elana Chapman</td>
                          <td style={halfTd}>Hilary Emer</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Christine Jackson</td>
                          <td style={halfTd}>Elishka Jepson</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Rosalie Krob</td>
                          <td style={halfTd}>Ashley Mohler</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Brittany Potter</td>
                          <td style={halfTd}>Rebecca Riley</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Jennifer Roach</td>
                          <td style={halfTd}>The Rogers Family</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Charlene Yauch</td>
                          <td style={halfTd}>Mary Beth White</td>
                        </tr>
                      </table>
                    </AccordionDetails>
                  </Accordion>
                </div>

                  <h1>Collegiate Giving Programs</h1>
                  <div className="container">
                  <p>
                  Collegiates and recent grads can also get involved in donor programs, with our Penguin Pals Program (P3) and the After the Cap & Gown Program. After a collegiate giving level is met, sisters are recognized for their support with a charm.
                  </p>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <h3 style={{ margin: 0 }}>The Penguin Pals Program</h3>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'block' }}>
                      <p>
                        <b>The Penguin Pals Program</b> is designed for all
                        college students! We understand that money can be tight
                        in college! Any undergraduate donor who contributes a
                        total of $100 during their active membership will
                        receive a silver penguin charm.
                      </p>
                      <table>
                        <tr>
                          <td style={halfTd}>Sarah Adamo</td>
                          <td style={halfTd}>Madison Arno</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Jordan Bair</td>
                          <td style={halfTd}>Greta Beesley</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Rylee Benes</td>
                          <td style={halfTd}>Gabrielle Bierhorst</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Lauren Brett</td>
                          <td style={halfTd}>Katy Brown</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Elaine Bunyan</td>
                          <td style={halfTd}>C&eacute;line Cano-Ruis</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Kira Chambers</td>
                          <td style={halfTd}>Soyeon Choi</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Brittany Cole</td>
                          <td style={halfTd}>Janna Croker</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Jenna Daghstani</td>
                          <td style={halfTd}>Emily Dalton</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Athena Davros</td>
                          <td style={halfTd}>Clara Discenzo</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Rashmi Drummond</td>
                          <td style={halfTd}>Ashley Durcholz</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Leigh Folger</td>
                          <td style={halfTd}>Larden Garland</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Kayla Gibbs</td>
                          <td style={halfTd}>Paige Given</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Robyn Griffifth</td>
                          <td style={halfTd}>Sarah Gustafson</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Maryjo Guthrie</td>
                          <td style={halfTd}>Kaellie Heelan</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Lexi Heironimus</td>
                          <td style={halfTd}>Pam Hintz</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Emily Jensen</td>
                          <td style={halfTd}>Elishka Jepson</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Rachel Jones</td>
                          <td style={halfTd}>Mandy Keeney</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Shannon Kenny</td>
                          <td style={halfTd}>Melissa Kontogiannis</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Kaitlin Kramer</td>
                          <td style={halfTd}>Caitline LaFontaine</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Celena Langlois</td>
                          <td style={halfTd}>Melisa Linenberger</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Avery Link</td>
                          <td style={halfTd}>Valierie Lomonte</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Alexandra Long</td>
                          <td style={halfTd}>Helen Lu</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Lindsey Marandina</td>
                          <td style={halfTd}>Caitlyn Martin</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Anna Mathena</td>
                          <td style={halfTd}>Amy Mazzanti</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Jessica McCall</td>
                          <td style={halfTd}>Kira Midkiff</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Leah Mlakar</td>
                          <td style={halfTd}>Ahsley Mohler</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Mady Murphy</td>
                          <td style={halfTd}>Allison Murray</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Sydney Negro</td>
                          <td style={halfTd}>Sydney Negro</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Ellie Nelson</td>
                          <td style={halfTd}>Caroline O&apos;Neill</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Katie Ohns</td>
                          <td style={halfTd}>Logan Osby</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Nadia Owen</td>
                          <td style={halfTd}>Olivia Paganelli</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Lauren Pelan</td>
                          <td style={halfTd}>Renee Pelan</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Elizabeth Pippert</td>
                          <td style={halfTd}>Marie Plaver</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Hannah Porter</td>
                          <td style={halfTd}>Hannah Remy</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Belle Riehemann</td>
                          <td style={halfTd}>Anne Roach</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Jennifer Roach</td>
                          <td style={halfTd}>Elissa Romer</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Gabrielle Salazar</td>
                          <td style={halfTd}>Abigail Schaar</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Cecilia Schiavoni</td>
                          <td style={halfTd}>Kristina Schnell</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Sidney Schrand</td>
                          <td style={halfTd}>Alaina Schwall</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Alexis Seibold</td>
                          <td style={halfTd}>Sirivan Seng</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Jessical Sofka</td>
                          <td style={halfTd}>Samantha Staltari</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Lindsay Stotler</td>
                          <td style={halfTd}>Madison Thomas</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Hannah Todd</td>
                          <td style={halfTd}>Miranda Vargas</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Lydia Wachtman</td>
                          <td style={halfTd}>Jenna Walker</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Lacey Westcarr</td>
                          <td style={halfTd}>Mary White</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Charlene Yauch</td>
                          <td style={halfTd}>Dana Yuen</td>
                        </tr>
                      </table>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <h3 style={{ margin: 0 }}>After the Cap-and-Gown</h3>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'block' }}>
                      <p>
                        <b>After the Cap-and-Gown</b> is designed for recent Phi
                        Sigma Rho alumnae. Donors who make donations of at least
                        $300 within five years of graduation from any
                        undergraduate program will receive a silver pyramid
                        charm.
                      </p>
                      <table>
                        <tr>
                          <td style={halfTd}>Janelle Becker</td>
                          <td style={halfTd}>Amara Bojrab</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Erika Bustos</td>
                          <td style={halfTd}>Alexandria Caliguire</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Rachel Chicchi</td>
                          <td style={halfTd}>Sarah Clark</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Sarah Dasilva</td>
                          <td style={halfTd}>Hillary Emer</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Colleen Fauz</td>
                          <td style={halfTd}>Sarah Fisk</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Caitlen Gahm</td>
                          <td style={halfTd}>Rachel Grandell</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Lexi Heironimus</td>
                          <td style={halfTd}>Mikayla Hopkins</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Katherine Hoppert</td>
                          <td style={halfTd}>Emily Jensen</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Mona Kim</td>
                          <td style={halfTd}>Rosalie Krob</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Helen Lu</td>
                          <td style={halfTd}>Sierra Ludwig</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Haley Moeder</td>
                          <td style={halfTd}>Ashley Mohler</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Grace Northcutt</td>
                          <td style={halfTd}>Renee Pelan</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Brittany Potter</td>
                          <td style={halfTd}>Leanne Price</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Janet Reyna</td>
                          <td style={halfTd}>Rachel Rich</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Anne Roach</td>
                          <td style={halfTd}>Samantha Rohner</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Kimberly Schlauch</td>
                          <td style={halfTd}>Rebecca Schmidt</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Katherine Shesko</td>
                          <td style={halfTd}>Caitline Stine</td>
                        </tr>
                        <tr>
                          <td style={halfTd}>Camille Stuczynski</td>
                          <td style={halfTd}></td>
                        </tr>
                      </table>
                    </AccordionDetails>
                  </Accordion>
                  </div>
                </div>
              <br />
              <br />
              <br />
              <p>
                <Link to="/">← Go Home</Link>
              </p>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
